
































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep, orderBy, toLower } from 'lodash';
import VueHtml2pdf from 'vue-html2pdf';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ConversationService from '@/services/crm/conversation.service';
import ConversationModel from '@/models/crm/conversation.model';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import ConversationLogModel from '@/models/crm/conversation-log.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import dayjs from '@/plugins/dayjs';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import CrmChatDialogMessage from '@/components/crm/chat-dialog-message.vue';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import Tooltip from '@/components/tooltip.vue';

@Component({
  components: {
    Tooltip,
    VueHtml2pdf,
    CrmChatDialogMessage,
  },
})
export default class CrmChatHistoryMessagesViewer extends Vue {
  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @Prop({ required: true })
  conversation!: ConversationModel;

  protocolDialogConversation!: ConversationModel;

  templates: ConversationTemplateModel[] = [];

  private readonly messageListItemPerPage = 30;

  messageListLimit = 30;

  private messages: ConversationMessageModel[] = [];

  private logs: ConversationLogModel[] = [];

  private exportLoader!: LoaderComponent;

  private imagesToBeLoaded = 0;

  private loadedImages = 0;

  async created(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      this.protocolDialogConversation = this.conversation;
      this.messages = await this.conversationService.getConversationMessages(
        this.protocolDialogConversation.waConversationId,
      );
      this.logs = await this.conversationService.getConversationLogs(this.protocolDialogConversation.id);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onShowMoreItems(): number {
    this.messageListLimit += this.messageListItemPerPage;
    return this.messageListLimit;
  }

  onGenerateReport(): void {
    this.exportLoader = this.setBusyLoader();

    this.messageListLimit = this.messages.length;

    this.prepareContent(1);
  }

  prepareContent(attempts: number): void {
    setTimeout(() => {
      if (this.loadedImages >= this.imagesToBeLoaded || attempts >= 15) {
        this.generatePdf();
      } else {
        this.prepareContent(attempts + 1);
      }
    }, 1000);
  }

  onImageStartLoad(): void {
    this.imagesToBeLoaded += 1;
  }

  onImageLoaded(): void {
    this.loadedImages += 1;
  }

  onExportProgress(progress: number): void {
    if (progress >= 100) {
      this.exportLoader.hide();
    }
  }

  async onOpenProtocol(id: number): Promise<void> {
    this.protocolDialogConversation = await this.conversationService.getConversation(id, true);
    this.messages = await this.conversationService.getConversationMessages(
      this.protocolDialogConversation.waConversationId,
    );
    this.logs = await this.conversationService.getConversationLogs(id);
  }

  generatePdf(): void {
    if (this.$refs.html2Pdf) {
      const html2Pdf = this.$refs.html2Pdf as VueHtml2pdf;
      html2Pdf.generatePdf();
    }
  }

  get messageList(): Array<ConversationMessageModel | ConversationLogModel> {
    let limit;
    if (this.messages.length > this.messageListLimit) {
      limit = this.messageListLimit;
    }

    const messages = this.messages
      .slice(0, limit || undefined)
      .filter((x) => x.id)
      .map((x) => {
        const obj = x;
        if (obj.reply?.id) {
          const replyMessage = cloneDeep(this.messages.find((y) => y.id === obj.reply?.id));
          if (replyMessage) {
            replyMessage.reply = undefined;
            obj.reply = replyMessage;
          }
        }
        return obj;
      });
    return orderBy([...messages, ...this.logs], ['date', 'id', 'isLog'], ['asc', 'asc', 'desc']);
  }

  get showMoreItemsBtn(): boolean {
    return this.messages.length > this.messageListLimit;
  }

  get cnpjCpfMask(): string {
    return this.protocolDialogConversation.cnpj && this.protocolDialogConversation.cnpj.length > 11
      ? '##.###.###/####-##' : '###.###.###-##';
  }

  get phoneMask(): string {
    return this.protocolDialogConversation?.numeroWhatsapp?.length > 12 ? '+## (##) #####-####' : '+## (##) ####-####';
  }

  get clientName(): string {
    if (this.protocolDialogConversation.tipo === ConversationTypeEnum.Prospect) {
      return this.protocolDialogConversation?.prospect?.fantasia
      || this.protocolDialogConversation?.prospect?.razaoSocial;
    }
    return this.protocolDialogConversation?.cliente?.nome || this.protocolDialogConversation?.cliente?.nomeFantasia;
  }

  get dateSeparator(): IKeyValue<string> {
    const data: IKeyValue<string> = {};

    let previousDate: dayjs.Dayjs;
    this.messageList.forEach((item, index) => {
      const currentDate = dayjs(item.date);

      if (index === 0 || (previousDate && !previousDate.isSame(currentDate, 'date'))) {
        data[index] = this.$t('crm.chatHistoryMessagesViewer.dateSeparator', {
          day: currentDate.format('DD'),
          month: this.$t(`global.months.${toLower(currentDate.format('MMMM'))}`),
          year: currentDate.format('YYYY'),
        }).toString();
      }
      previousDate = currentDate;
    });
    return data;
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.chatMessagesViewerContainer,
      canCancel: false,
    });
  }
}
